import {useContext, useEffect, useState} from 'react';
import {updateBlockHeaderTitle} from "./blockUtils";
import {observer} from "mobx-react";
import {StoreContext} from "../../../stores/StoreLoader";
import NotificationManager from "../../notifications/NotificationManager";
import InlineTextEditor from "../../utilities/InlineTextEditor";
import styles from "./styles/BlockHead.module.scss";
import classNames from 'classnames';
import {IView} from "../../../stores/InterfaceStore";

const editableHeaderTypes = [
    'news',
    'poll',
    'eventlist',
    'quicklink',
    'sharedfiles'
];

const BlockHead = observer((props: {
    blockType: string,
    className?: string,
    editableTitle?: string,
    blockId: string,
    children?: any,
}) => {
    const {i18nStore, userStore, organizationStore, interfaceStore} = useContext(StoreContext);
    const {className = "", children = "", blockType, blockId, editableTitle = "", ...validDomAttributes} = props;

    const [title, setTitle] = useState(editableTitle);

    useEffect(() => {
        // update editable title if title changes externally i.e. from add edit modal
        if (editableTitle && editableTitle !== title) {
            setTitle(editableTitle);
        }
    }, [editableTitle]);

    const contentEditable = userStore.editor && editableHeaderTypes.includes(props.blockType);

    const wrapperClassName = classNames({
        "sb-blockHead": true,
        "sb-organization-color-block-bg": true,
        "sb-block-head-border": true,
        [className]: className,
        "hidden": interfaceStore.view === IView.SEARCH,
    })

    return <div className={wrapperClassName}
                role="heading"
                data-blocktype={blockType} {...validDomAttributes}>
        {children}
        {editableTitle && <InlineTextEditor
            key={blockId}
            canEdit={contentEditable}
            text={title}
            wrapperClassName={styles.editableTitle}
            isSimple={true}
            handleTextChange={value => setTitle(value)}
            handleSave={() => {
                updateBlockHeaderTitle(blockId, organizationStore.currentOrganization.id, blockType, title).then((res) => {
                    NotificationManager.success("Title updated successfully.");
                }).catch((err) => {
                    console.error("ERROR: ", err);
                    NotificationManager.error("Title update error");
                })
            }}
        ><span>{title}</span></InlineTextEditor>}
    </div>
});

export default BlockHead;
