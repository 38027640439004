import PropTypes from 'prop-types';

const col1_fill = "#009688";
const col2_fill = "#13547a";
const col3_fill = "#80d0c7";

export default function SchoolBlocksLoadingIndicator(props) {
    return <svg viewBox={"0 0 100 100"} width={props.size} height={props.size}>
        <style>
            {`
            #col1 rect {
                animation: bounce 1.5s ease infinite;
                fill: ${props.fill || col1_fill};
            }
            #col2 rect {
                animation: bounce 1.5s ease 0.2s infinite;
                fill: ${props.fill || col2_fill};
            }
            #col3 rect {
                animation: bounce 1.5s ease 0.4s infinite;
                fill: ${props.fill || col3_fill};
            }
            #text circle:nth-of-type(1) {
              animation: appear 1s ease infinite;
            }
            #text circle:nth-of-type(2) {
              animation: appear 1s ease 0.2s infinite;
            }
            #text circle:nth-of-type(3) {
              animation: appear 1s ease 0.4s infinite;
            }
            @keyframes bounce {
                0% {
                    transform: scaleY(3);
                }
                30% {
                    transform: scaleY(1);
                }
                100% {
                    transform: scaleY(1.2);
                }
            }
            @keyframes appear {
              from {
                opacity: 1;
              }
              to {
                opacity: 0;
              }
            }
            `}
        </style>
        <defs>
            <clipPath id="outline">
                <rect x="0" y="0" width="100" height="92.5"/>
            </clipPath>
        </defs>
        <rect rx="5" ry="5" fill={props.background} width="100" height="100"/>
        <g clipPath="url(#outline)">
            <g id="col1">
                <rect rx="2" ry="2" height="18" width="25" x="7.5" y="39.5"/>
                <rect rx="2" ry="2" height="33" width="25" x="7.5" y="59.5"/>
            </g>
            <g id="col2">
                <rect rx="2" ry="2" height="20" width="25" x="37.5" y="22.5"/>
                <rect rx="2" ry="2" height="48" width="25" x="37.5" y="44.5"/>
            </g>
            <g id="col3">
                <rect rx="2" ry="2" height="32" width="25" x="67.5" y="60.5"/>
                <rect rx="2" ry="2" height="48" width="25" x="67.5" y="10.5"/>
            </g>
        </g>
    </svg>
}

SchoolBlocksLoadingIndicator.defaultProps = {
    size: 50,
    background: "transparent"
};

SchoolBlocksLoadingIndicator.propTypes = {
    fill: PropTypes.string,
    background: PropTypes.string,
    size: PropTypes.number,
};
