import * as React from 'react';

import styles from './styles/ThirdPartyIframe.module.scss';
import {useEffect, useRef, useState} from "react";

interface ThirdPartyIframeProps extends React.ComponentPropsWithRef<"iframe"> {
    contentUrl: string,
    positioning: "relative" | "absolute"
}

const ThirdPartyIframe: React.FC<ThirdPartyIframeProps> = props => {
    const { contentUrl, src, ...remainingProps } = props;
    const [contentLoaded, setContentLoaded] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    useEffect(() => {
        if (iframeRef.current) {
            // there appears to be a bug in NextJS where onLoad iframe events aren't always fired on initial page load
            // this fixes that and was suggested here: https://github.com/vercel/next.js/issues/39451
            iframeRef.current.src = src as string;
        }
        iframeRef.current?.addEventListener('load', () => setContentLoaded(true))
        return () => {
            iframeRef.current?.removeEventListener('load', () => setContentLoaded(true))
        }
    }, [src]);

    return <div style={{position: remainingProps.positioning}} className={styles.iframeContainer}>
        <iframe ref={iframeRef} {...remainingProps} />
        {contentLoaded || <div className={styles.loadingIndicator}>
            <div>
                <span>Loading...</span>
                <p>If contents fail to load, please reload page.</p>
            </div>
        </div>}
    </div>
};

export default ThirdPartyIframe;
