import {observer} from "mobx-react";
import styles from "./styles/NormalizedFileBlock.module.scss"
import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import ThirdPartyIframe from "../../../utilities/ThirdPartyIframe";
import ClickableLink from "../../../utilities/ClickableLink";
import {ISharedFilesBlockProps} from "./SharedFilesBlock";
import {useContext} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";

export enum ViewerTypeEnum {
    GOOGLE = "google",
    GOOGLEVIEWER = "googleviewer",
    HTML5AUDIO = "html5audio",
    HTML5VIDEO = "html5video",
    IFRAME = "iframe",
    IMAGE = "image",
    LINK = "link",
    OFFICE365 = "office365",
    PDFJS = "pdfjs",
}

export function FileViewer(props: {
    viewerType: ViewerTypeEnum,
    viewLink: string,
    thirdPartyId: string,
    embeddedViewLink?: string,
    uniqueId: string | number,
}) {
    let embedLink;
    let positioning: "absolute" | "relative" = "absolute"
    if (props.uniqueId) {
        const lenOfId = String(props.uniqueId).split('-').length
        positioning = (lenOfId===1) ? "relative" : "absolute"
    }

    switch (props.viewerType) {
        case ViewerTypeEnum.HTML5VIDEO:
            return <video src={props.viewLink} controls />;

        case ViewerTypeEnum.HTML5AUDIO:
            return <audio src={props.viewLink} controls />;

        case ViewerTypeEnum.IMAGE:
            return <img src={props.viewLink} />;

        case ViewerTypeEnum.LINK:
            return <div style={{textAlign: 'center', padding: '1rem'}}>
                <a href={props.viewLink}  className="btn sb-organization-color-element-bg" target="_blank"
                   rel="noopener noreferrer"
                   style={{marginTop: '30%'}}>Click to view the file</a>
            </div>;

        case ViewerTypeEnum.OFFICE365:
            embedLink = props.embeddedViewLink || props.viewLink;
            return <ThirdPartyIframe src={embedLink}
                                     contentUrl={props.viewLink}
                                     frameBorder="no"
                                     tabIndex={-1}
                                     positioning={positioning}
            />;

        case ViewerTypeEnum.GOOGLE:

            // mimic what is in FileBlock - we need to consolidate these at some point.
            return <ThirdPartyIframe src={`https://docs.google.com/viewer?srcid=${props.thirdPartyId}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                                     id={`shared-file-iframe-${props.uniqueId}`}
                                     contentUrl={props.viewLink}
                                     frameBorder="no"
                                     tabIndex={-1}
                                     positioning={positioning}
            />;

        case ViewerTypeEnum.IFRAME:
            embedLink = props.embeddedViewLink || props.viewLink;
            return <ThirdPartyIframe src={embedLink}
                                     contentUrl={props.viewLink}
                                     frameBorder="no"
                                     aria-hidden={true}
                                     tabIndex={-1}
                                     positioning={positioning}
            />;

        case ViewerTypeEnum.GOOGLEVIEWER:
        default:
            const viewerUrl = 'https://docs.google.com/viewer?embedded=true&url=' + encodeURIComponent(props.viewLink);
            return <ThirdPartyIframe src={viewerUrl}
                                     contentUrl={props.viewLink}
                                     frameBorder="no"
                                     aria-hidden={true}
                                     tabIndex={-1}
                                     positioning={positioning}
            />;
    }
}

const NormalizedFileBlock = observer((props: ISharedFilesBlockProps) => {
    const {interfaceStore} = useContext(StoreContext);
    const file = props.blockObj.file as NonNullable<ISharedFilesBlockObj["file"]>;

    let headerIcon;
    const headerTitle = props.blockObj.blockModel.title ? props.blockObj.blockModel.title : 'Shared Files'

    if (file?.icon) {
        headerIcon = <img src={file.icon} alt={ headerTitle } aria-hidden="true"
                          style={{display: 'inline-block', marginRight: '0.3em', height: '1.6em',  width: '1.6em',
                              verticalAlign: 'top'}} />;
    } else {
        switch (file?.viewerType) {
            case ViewerTypeEnum.GOOGLE:
                headerIcon = <div className="fab fa-fw fa-google" aria-hidden="true"
                                  style={{color: 'inherit', display: 'inline-block', fontSize: '1.4em', marginRight: '0.3em'}}
                />;
                break;
            case ViewerTypeEnum.OFFICE365:
                headerIcon = <div className="sb-office365-icons sb-office365-logo" aria-hidden="true"
                                  style={{color: 'inherit', display: 'inline-block', fontSize: '1.4em', marginRight: '0.3em'}}
                />;
                break;
        }
    }

    return (
        <div className={styles.normalizedFilesBlockWrapper}>
            {interfaceStore.view === "organization" ||
                <BlockHead blockType={"sharedfiles"} blockId={props.blockObj.id}>
                    <ClickableLink href={props.blockObj.fileUrl || file.viewLink} style={{position: 'static'}} title={'View ' + (file.title || file.type)}>
                        {headerIcon}
                        <span>{headerTitle}</span>
                    </ClickableLink>
                </BlockHead>
            }
            <div className="sb-blockContent sb-organization-color-element-font clearfix" style={{padding: 0}}>
                <FileViewer
                    viewerType={file.viewerType as ViewerTypeEnum}
                    viewLink={file.viewLink as string}
                    thirdPartyId={file.id as string}
                    embeddedViewLink={file.embeddedViewLink}
                    uniqueId={props.blockObj.id}
                />
            </div>
        </div>
    );
})

export default NormalizedFileBlock;
