import {PropsWithChildren, useContext, useEffect} from 'react';
import * as React from 'react';
import {Scrollbar} from "react-scrollbars-custom";
import {observer} from "mobx-react";
import {StoreContext} from "../../../stores/StoreLoader";
import {reactScrollBarsCustomWidth} from "../../../utils/SchoolBlocksUtilities";

const blocksToShowExpandButton = ['news', 'eventlist', 'message', 'poll', 'instagram', 'staff', "sharedfiles", "quicklink"];

export interface IBlockScrollerProps extends PropsWithChildren {
    blockRef: React.RefObject<HTMLDivElement>,
    className?: string,
    blockObj: AnyBlockObj,
    htmlId: string,
    setShowExpandButton: React.SetStateAction<any>,
    outsideGrid: boolean,
    color?: string,
}

const BlockScroller: React.FC<IBlockScrollerProps> = observer(({
    blockRef,
    className = "",
    blockObj,
    htmlId,
    setShowExpandButton,
    outsideGrid,
    color = "rgba(0,0,0,.4)",
    children,
    ...validHtmlAttributes
}) => {

    const {interfaceStore} = useContext(StoreContext);
    const shouldShowExpandButton = blocksToShowExpandButton.includes(blockObj.blockType) &&
        interfaceStore.breakpoint === "break-point-xs" && !outsideGrid;

    useEffect(() => {
        if (blockRef.current) {
            setShowExpandButton(blockRef.current.scrollHeight > blockRef.current.clientHeight && shouldShowExpandButton)
        }
    }, [blockRef.current, blockObj.asyncLoaded])

    if (shouldShowExpandButton) {
        return <div className={className} style={{width: "100%", height: "100%", position: "absolute"}}>
            {children}
        </div>
    } else {
        return <Scrollbar
            scrollbarWidth={reactScrollBarsCustomWidth}
            disableTrackYWidthCompensation={true}
            style={{width: "100%", height: "100%", position: "absolute"}}
            renderer={props => {
                const {elementRef, ...restProps} = props;
                restProps.className += " sb-blockScroller " + className;
                return <div {...validHtmlAttributes} {...restProps} ref={elementRef}/>;
            }}
            thumbYProps={{
                className: "blockScrollbar",
                style: {cursor: "default", background: color},
            }}
            trackYProps={{
                style: {width: 6, background: "transparent", zIndex: 501},
            }}
            trackXProps={{
                style: {width: 6, background: "transparent"},
            }}
            wrapperProps={{
                style: {right: 0},
            }}
            scrollerProps={{
                tabIndex: 0,
                className: blockObj.id,
            }}
        >
            {children}
        </Scrollbar>
    }
})

export default BlockScroller;
