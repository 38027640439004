import { Component } from "react";
import SchoolBlocksLoadingIndicator from "../../utilities/SchoolBlocksLoadingIndicator";

export default class LoadingBlock extends Component {
    render() {
        return (
            <div className="sb-block-loading">
                <SchoolBlocksLoadingIndicator fill={"#E0E0E0"}/>
            </div>
        );
    }
}
